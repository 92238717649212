import 'bootstrap';

jQuery(function($) {

    /*
    * Footer: Scroll to top button
    *
    * */
    $('#scroll-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 400);
        return false;
    });

    /*
    *    Primary Navigation: Dropdown behaviour.
    *
    *    Overrides default Bootstrap behaviour, and now dropdown menu appears on over main link,
    *    this behaviour is disabled on smaller devices where plus/minus button is revealed.
    *
    * */
    if ($(window).width() > 991) {
        $('.navbar .dropdown').hover(function() {
            $(this).children('.dropdown-menu').first().stop(true, true).slideDown();

        }, function() {
            $(this).children('.dropdown-menu').first().stop(true, true).delay(100).slideUp();

        });
    }

    /*
    * Primary Navigation: Mobile menu.
    *
    * Plus/Minus icon for dropdown menu which reveals the dropdown menu.
    *
    * */

    /*
    * Plus button: Show the dropdown
    * */
    $('.btn-dropdown-show').click(function(e) {
        $(this)
            .hide()
            .siblings('.btn-dropdown-hide').show()
            .parent().siblings('.dropdown-menu').first().stop(true, true).slideDown();
    });

    /*
    * Minus button: Hide the dropdown
    * */
    $('.btn-dropdown-hide').click(function() {
        $(this)
            .hide()
            .siblings('.btn-dropdown-show').show()
            .parent().siblings('.dropdown-menu').first().stop(true, true).delay(100).slideUp();
    });

    /*
    * Primary Navigation: Dropdown menu header link.
    *
    * Overrides default Bootstrap behaviour and makes the header link on a dropdown menu clickable.
    *
    * */
    $('.navbar .dropdown > a').click(function() {
        location.href = this.href;
    });


    $(document).scroll(function () {
        var $nav = $("nav.navbar");
        $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
    });


    // Field show/hide
    const $show_hide_fields = [];

    $('#online-quotation div').each(function() {
        if ($(this).data('show-if-question')) {
            $(this).hide();

            $show_hide_fields.push({
                ifQuestion: '#' + $(this).data('show-if-question'),
                operator: $(this).data('show-if-operator'),
                ifValue: $(this).data('show-if-value'),
                field: '#' + this.id
            });
        }
    });

    $show_hide_fields.forEach(show => {
        $(show.ifQuestion).change(function() {

            switch(show.operator) {
                case ">":
                    if(parseInt($(this).val()) > show.ifValue) {
                        $(show.field).show();
                    } else {
                        $(show.field).hide();
                    }
                    break;
                default:
                    if(show.ifValue === $(this).val()) {
                        $(show.field).show();
                    } else {
                        $(show.field).hide();
                    }
            }
        });
    });
});
